import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "osipov_vs_liepaja" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Catenaccio achieved a substantial victory in the FIFA Dispute Resolution Chamber, which held FK Liepaja liable to compensate financial damages to our client, <Link to={ `/en/players/osipov` }>Artem Osipov</Link>, for a breach of an employment contract. On top of that, the firm succeeded in convincing the Chamber to condemn FK Liepaja to pay the outstanding contractual remuneration to our client.</p>
          <p>The firm represented a footballer, Artem Osipov, who was subjected to financial and psychological duress by both the president and coach of FK Liepaja, at that time, and eventually was forced to leave his employment.The football club alleged that Artem breached his employment contract, and instructed a Swiss law firm with an experience of 150 years to advance this claim against the footballer.</p>
          <p>Catenaccio grasped early on and targeted vulnerability in FK Liepaja's claim, building-up a strategy around information and documents regarding the circumstances surrounding the alleged breach. This proved a critical accelerant to the collapse of the case argued by Swiss law firm. Together with co-counsel, the firm obtained admissions contradicting the false narrative of the management of FK Liepaja.</p>
          <p>Catenaccio's argument was based on a subtle understanding and application of the law on breach of employment contract.</p>
          <p>The significance of our victory is best summarised by what the client told us upon hearing the news: “I would not trust to the force of law if I did not retain your professional services”.</p>
        </div>
      </div>
    )
  }
}